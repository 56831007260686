.c-header--disclaimer {
  top: 50px;
}

.disclaimer {
  position: fixed;
  left: 0;
  top: 0;
  
  width: 100%;
  height: 50px;
  
  box-shadow: inset 0 -20px 60px 0 rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(88deg, #791717, #aa2b2b);
  font-weight: 300;
  font-size: 12px;
  color: #ffffff;
  
  z-index: 100;
  
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
}

.disclaimer.disclaimer--hidden {
  display: none;
}

.disclaimer__content {
  margin: 0 20px;
  display: flex;
  align-items: center;
  
  @media (min-width: 768px) {
    margin: 0;
    width: 690px;
    box-sizing: border-box;
    padding-left: 20px;
  }
  
  @media (min-width: 1024px) {
    padding: 0;
    width: 944px;
  }
  
  @media (min-width: 1280px) {
    width: 1180px;
  }
  
  @media (min-width: 1500px) {
    width: 1400px;
  }
}

.disclaimer__text {
  margin-right: 10px;
}

.disclaimer__close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-left: auto;
  flex: none;
  
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.disclaimer__close-icon {
  position: relative;
  display: block;
  width: 3px;
  height: 3px;
  
  &::before,
  &::after {
    content: '';
    display: block;
    width: 18px;
    height: 2px;
    position: absolute;
    background-color: #fff;
    left: -8px;
  }
  
  &::before {
    transform: rotate(45deg);
  }
  
  &::after {
    transform: rotate(-45deg);
  }
}

.disclaimer__content a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
}

a.disclaimer__link {
  text-decoration: underline;
  font-weight: 300;
  
  &:active,
  &:hover,
  &:visited {
    color: #ffffff;
    text-decoration: none;
  }
}
