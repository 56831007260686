.storage-warehouse {
  padding-top: 50px;
  overflow: hidden;
  
  font-family: Montserrat, sans-serif;
  
  @media (min-width: 1024px) {
    height: 643px;
  }
  
  @media (min-width: 1280px) {
    padding-top: 78px;
    height: 975px;
  }
  
  @media (min-width: 1500px) {
    padding-top: 110px;
    height: 1222px;
  }
}

.storage-warehouse__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  
  padding: 0 20px;
  
  @media (min-width: 768px) {
    width: 650px;
    margin: 0 auto;
    padding: 0;
  }
  
  @media (min-width: 1024px) {
    width: 944px;
  }
  
  @media (min-width: 1280px) {
    width: 1200px;
  }
  
  @media (min-width: 1500px) {
    width: 1400px;
  }
}

.storage-warehouse__picture {
  display: block;
  
  margin-right: -20px;
  margin-top: 36px;
  
  @media (min-width: 768px) {
    position: absolute;
    left: 0;
    top: 120px;
    width: 1075px;
    margin: 0;
  }
  
  @media (min-width: 1024px) {
    left: 398px;
    top: 140px;
    width: 1645px;
  }
  
  @media (min-width: 1280px) {
    left: 522px;
    top: 317px;
    width: 2111px;
  }
  
  @media (min-width: 1500px) {
    left: 522px;
    top: 338px;
    width: 2764px;
  }
}

.storage-warehouse__img {
  display: block;
  width: 100%;
}

.storage-warehouse__title {
  margin: 0;
  order: -1;
  
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 200;
  line-height: 1.31;
  letter-spacing: 0.33px;
  color: #c9394b;
  
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 1.06;
    letter-spacing: 0.4px;
  }
  
  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 1.94;
    letter-spacing: 0.45px;
  }
  
  @media (min-width: 1280px) {
    font-size: 70px;
    line-height: 1.73;
    letter-spacing: 0.88px;
  }
  
  @media (min-width: 1500px) {
    font-size: 80px;
    line-height: 1.25;
    letter-spacing: 1px;
  }
}

.storage-warehouse__title span {
  display: block;
}

.storage-warehouse__title .big {
  text-transform: uppercase;
  font-size: 50px;
  line-height: 1;
  letter-spacing: 0.63px;
  
  @media (min-width: 768px) {
    font-size: 50px;
    line-height: 1;
    letter-spacing: 0.63px;
  }
  
  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 0.83;
    letter-spacing: 0.75px;
  }
  
  @media (min-width: 1280px) {
    font-size: 130px;
    letter-spacing: 1.63px;
  }
  
  @media (min-width: 1500px) {
    font-size: 150px;
    line-height: 0.67;
    letter-spacing: 1.88px;
    margin-bottom: 22px;
  }
}

.storage-warehouse__title .big sup {
  font-size: 32px;
  
  @media (min-width: 1280px) {
    font-size: 80px;
  }
  
  @media (min-width: 1500px) {
    font-size: 96px;
  }
}

.storage-warehouse__title br {
  @media (min-width: 568px) {
    display: none;
  }
}

.storage-warehouse__list {
  margin: 27px 0 0 0;
  padding: 0;
  list-style: none;
  
  @media (min-width: 568px) {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  @media (min-width: 768px) {
    margin-top: 362px;
  }
  
  @media (min-width: 1024px) {
    display: block;
    width: 300px;
    margin-top: 70px;
  }
  
  @media (min-width: 1280px) {
    margin-top: 118px;
    width: 360px;
  }
  
  @media (min-width: 1500px) {
    margin-top: 242px;
    width: 380px;
  }
}

.storage-warehouse__item {
  padding-bottom: 30px;
  
  @media (min-width: 568px) and (max-width: 767px) {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-shrink: 0;
  }
  
  @media (min-width: 768px) and (max-width: 1023px){
    width: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
    flex-shrink: 0;
  }
  
  @media (min-width: 1024px) {
    padding-bottom: 40px;
  }
}

.storage-warehouse__item-title {
  display: block;
  
  font-size: 22px;
  font-weight: normal;
  line-height: 1.27;
  color: #151515;
  
  @media (min-width: 1280px) {
    font-size: 24px;
    line-height: 1.88;
  }
  
  @media (min-width: 1500px) {
    font-size: 28px;
    line-height: 1.61;
  }
}

.storage-warehouse__item-text {
  margin: 10px 0 0 0;
  
  font-size: 14px;
  font-weight: normal;
  line-height: 1.6;
  color: #151515;
  
  @media (min-width: 1280px) {
    margin: 0;
    font-size: 16px;
    line-height: 1.85;
  }
  
  @media (min-width: 1500px) {
    font-size: 18px;
    line-height: 1.67;
    margin-top: 9px;
  }
}

.storage-warehouse__item-text br {
  @media (min-width: 1024px) {
    display: none;
  }
}

.storage-warehouse .c-button {
  width: 100%;
  
  &--mob {
    display: block;
  }
  
  &--desktop {
    display: none;
  }
  
  @media (min-width: 568px) {
    width: 50%;
  }
  
  @media (min-width: 768px) {
    width: 50%;
    height: 50px;
  
    font-size: 14px;
    letter-spacing: 0.8px;
    padding: 17.5px 20.5px 13.5px;
  }
  
  @media (min-width: 1024px) {
    width: 280px;
  }
  
  @media (min-width: 1280px) {
    width: 390px;
    height: 72px;
    margin-top: 35px;
    font-size: 22px;
    letter-spacing: 1.1px;
    padding: 11.5px 20.5px 13.5px;
  
    &--mob {
      display: none;
    }
  
    &--desktop {
      display: block;
    }
  }
  
}

.storage-warehouse .storage-button__text {
  &--mob {
    display: block;
  }
  
  &--desktop {
    display: none;
  }
  
  @media (min-width: 1280px) {
    &--mob {
      display: none;
    }
    
    &--desktop {
      display: block;
    }
  }
}
