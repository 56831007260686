@import "./keyframes.scss";
@import "./animations.scss";

.intro-container {
  margin-top: -30px;

  @media (max-width: 1499px) {
    margin-top: 0;
  }
}

.c-catalog-block {
  display: flex;
  margin-top: 0;

  @media (max-width: 1023px) {
    display: none;
  }
}

.c-catalog-side {
  padding-top: 46px;
  @media (max-width: 1499px) {
    padding-top: 28px;
  }
}

.c-catalog-title {
  margin-top: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: #c9394b;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 20px;
  line-height: normal;
  margin-bottom: 10px;
  @media (max-width: 1499px) {
    font-size: 13px;
    margin-bottom: 6px;
  }
}

.c-catalog-slider {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 300px;

  @media (max-width: 1499px) {
    width: 175px;
  }
}

.intro-subtitle {
  margin-bottom: 30px;
  @media (max-width: 1499px) {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.custom-catalog-block {
  transform: translateX(-7px);
  margin-bottom: 30px;;
  width: 60%;
  height: 17%;
  justify-content: space-between;

  @media (max-width: 1499px) {
    margin-bottom: 20px;
  }
}

.hover_left {
  padding-right: 132px;
  width: 51%;
  z-index: 2;
  cursor: pointer;

  @media (max-width: 1499px) {
    padding-right: 95px;
  }
}

.hover_right {
  padding-left: 125px;
  width: 50%;
  z-index: 2;
  cursor: pointer;

  @media (max-width: 1499px) {
    padding-left: 90px;
  }

}

.intro-title {
  margin-bottom: 0;
  @media (max-width: 1499px) {
    padding-left: 0;
    transform: translateX(3%);
  }
}

.c-catalog-subtitle {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
  font-size: 14px;
  line-height: 11px;
  text-transform: uppercase;

  @media (max-width: 1499px) {
    display: block;
    font-size: 9px;
    line-height: 11px;
  }
}


.left-side {
  text-align: right;
  margin-right: 26px;
  @media (max-width: 1499px) {
    margin-right: 12px;
  }
}

.right-side {
  text-align: left;
  margin-left: 26px;
  @media (max-width: 1499px) {
    margin-left: 12px;
  }
}

.c-catalog-link {
  margin-top: 12px;
  font-size: 14px;
  color: #c9394b;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #000000;
  }

  @media (max-width: 1499px) {
    margin-top: 8px;
    font-size: 9px;
  }
}

.right-link {
  position: relative;
  width: max-content;
  display: none;

  &:hover {
    &:before {
      position: absolute;
      top: -5px;
      right: -25px;
      opacity: 1;
      content: '';
      width: 24px;
      height: 24px;
      background-image: url("/assets/images/main-screen/arrow-hover.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transition: all 0.3s ease;


      @media (max-width: 1499px) {
        width: 16px;
        height: 16px;
        top: -4px;
        right: -18px;
        background-image: url("/assets/images/main-screen/arrow-md-hover.svg");
      }
    }
  }

  &::before {
    position: absolute;
    top: -5px;
    right: -25px;
    opacity: 1;
    content: '';
    width: 24px;
    height: 24px;
    background-image: url("/assets/images/main-screen/arrow.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: all 0.3s ease;


    @media (max-width: 1499px) {
      width: 16px;
      height: 16px;
      top: -4px;
      right: -18px;
      background-image: url("/assets/images/main-screen/arrow-md.svg");
    }
  }
}

.left-link {
  position: relative;
  width: max-content;
  margin-left: auto;

  &:hover {
    &:before {
      position: absolute;
      top: -5px;
      right: -25px;
      opacity: 1;
      content: '';
      width: 24px;
      height: 24px;
      background-image: url("/assets/images/main-screen/arrow-hover.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;


      @media (max-width: 1499px) {
        width: 16px;
        height: 16px;
        top: -4px;
        right: -18px;
        background-image: url("/assets/images/main-screen/arrow-md-hover.svg");
      }
    }
  }

  &::before {
    position: absolute;
    top: -5px;
    left: -25px;
    opacity: 1;
    content: '';
    width: 24px;
    height: 24px;
    background-image: url("/assets/images/main-screen/arrow.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: rotate(180deg);

    @media (max-width: 1499px) {
      width: 16px;
      height: 16px;
      top: -4px;
      left: -18px;
      background-image: url("/assets/images/main-screen/arrow-md.svg");
    }
  }
}

.slider-overlay {
  position: absolute;
  top: -15px;
  right: 0;
  width: 50%;
  height: 200px;
  border-left: 3px solid #c9394b;

  @media (max-width: 1499px) {
    height: 120px;
    top: 0;
    border-left: 2px solid #c9394b;
  }
}


.img-left-animate {
  position: absolute;
  height: 79%;
  top: 5px;
  left: 26px;
  width: 83%;
  z-index: 1;
  clip: rect(auto, 121px, auto, auto);
  @media (max-width: 1499px) {
    top: 15px;
    left: 16px;
    height: 65%;
    clip: rect(auto, 72px, auto, auto);
  }

}

.img-right-animate {
  position: absolute;
  top: 5px;
  right: 28px;
  height: 79%;
  z-index: 1;
  width: 83%;
  clip: rect(auto, auto, auto, 127px);
  @media (max-width: 1499px) {
    top: 15px;
    right: 16px;
    height: 65%;
    clip: rect(auto, auto, auto, 72px);
  }
}


.img-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.slider-image {
  object-fit: cover;
  width: max-content;
  height: 100%;
}


.left-image {
  position: absolute;
  left: 0;
  top: 0;
}

.right-image {
  position: absolute;
  right: 0;
  top: 0;


  @media (max-width: 1499px) {
    right: 1px;
  }
}

.slider-bar {
  display: block;
  position: absolute;
  height: 205px;
  width: 3px;
  top: -15px;
  left: 49%;
  z-index: 4;
  background-color: #c9394b;
  @media (max-width: 1499px) {
    height: 122px;
    top: 0;
    width: 2px;
  }
}


.c-about-text {
  position: absolute;
  padding: 0;
  border: none;
  background-color: transparent;
  bottom: 100px;
  font-size: 16px;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  transition: color 0.3s ease;

  &:hover {
    color: #c9394b;
  }

  @media (max-width: 1499px) {
    font-size: 10px;
    margin-bottom: 6px;
  }


  @media (max-width: 1023px) {
    display: none;
  }
}

.video-link {
  position: absolute;
  bottom: 26px;
  right: 26px;
  margin-top: 8.2vh;
  text-decoration: none;
  padding-left: 6px;
  white-space: nowrap;
  display: flex;
  align-items: center;


  span {
    margin-right: 10px;
    margin-top: 3px;
    color: #ffffff;
    transition: opacity 0.3s ease;
    opacity: 0;
    font-size: 16px;
    text-transform: uppercase;
  }

  g {
    transition: stroke 0.3s ease;
  }

  @media (max-width: 1499px) {
    bottom: 18px;
    right: 18px;
    span {
      font-size: 10px;
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }

  &:hover {
    g {
      stroke: #c9394b;
    }

    span {
      opacity: 1;
    }
  }
}

.video-icon {
  width: 30px;
  height: 24px;
  scroll-width: 1;
  @media (max-width: 1499px) {
    width: 20px;
    height: 16px;
    scroll-width: 0.5;
  }
}


.video-icon--hovered {
  g {
    transition: stroke 0.2s ease;
    stroke: #c9394b;
  }
}

.bar-center-right-ani {
  top: 4px;
  left: 90%;
  height: 139px;
  animation: bar-center-right ease 0.3s;
  @media (max-width: 1499px) {
    left: 90%;
    height: 83px;
    top: 15px;
    animation: bar-center-right-md ease 0.3s;
  }
}

.side-link-wrapper {
  text-decoration: none;
}

.bar-right-center-ani {
  top: -16px;
  left: 49%;
  height: 205px;
  animation: bar-right-center ease 0.3s;
  @media (max-width: 1499px) {
    left: 49%;
    height: 122px;
    top: 0;
    animation: bar-right-center-md ease 0.3s;
  }
}

.bar-center-left-ani {
  top: 4px;
  left: 8%;
  height: 139px;
  animation: bar-center-left ease 0.3s;
  @media (max-width: 1499px) {
    left: 8%;
    height: 83px;
    top: 15px;
    animation: bar-center-left-md ease 0.3s;
  }
}

.bar-left-center-ani {
  top: -16px;
  left: 49%;
  height: 205px;
  animation: bar-left-center ease 0.3s;

  @media (max-width: 1499px) {
    left: 49%;
    height: 122px;
    top: 0;
    animation: bar-left-center-md ease 0.3s;
  }
}

.line-custom {
  bottom: 40px;
}
