.storage-advantage {
  padding: 50px 0 35px 0;
  background-color: #f5f5f5;
  
  font-family: Montserrat, sans-serif;
  
  @media (min-width: 568px) {
    padding: 50px 0 40px 0;
  }
  
  @media (min-width: 768px) {
    padding: 50px 0 45px 0;
  }
  
  @media (min-width: 1280px) {
    padding: 82px 0 80px 0;
  }
  
  @media (min-width: 1500px) {
    padding: 102px 0 90px 0;
  }
}

.storage-advantage__inner {
  padding: 0 20px 0 20px;
  
  
  @media (min-width: 768px) {
    width: 650px;
    margin: 0 auto;
    padding: 0;
  }
  
  @media (min-width: 1024px) {
    width: 944px;
  }
  
  @media (min-width: 1280px) {
    width: 1200px;
  }
  
  @media (min-width: 1500px) {
    width: 1400px;
  }
}

.storage-advantage__title {
  margin: 0;
  
  font-size: 26px;
  font-weight: 200;
  line-height: 1.31;
  letter-spacing: 0.33px;
  color: #c9394b;
  
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 1.06;
    letter-spacing: 0.4px;
  }
  
  @media (min-width: 1024px) {
    width: 60%;
    
    font-size: 36px;
    line-height: 1.22;
    letter-spacing: 0.45px;
  }
  
  @media (min-width: 1280px) {
    width: 100%;
  
    font-size: 70px;
    line-height: 1.29;
    letter-spacing: 0.88px;
  }
  
  @media (min-width: 1500px) {
    font-size: 80px;
    line-height: 1.25;
    letter-spacing: 1px;
  }
}

.storage-advantage__list {
  margin: 0;
  padding: 0;
  list-style: none;
  
  @media (min-width: 568px) and (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.advantage-item{
  padding-top: 30px;
  padding-bottom: 20px;
  
  color: #151515;
  
  &::before {
    content: '';
    display: block;
    width: 170px;
    height: 110px;
    
    margin-bottom: 5px;
    
    background-position: left top;
    background-repeat: no-repeat;
  }
  
  &--accountability::before {
    background-image: url("../images/storage/icon-advantage--accountability.svg");
    background-size: auto 104px;
  }
  
  &--fin::before {
    background-image: url("../images/storage/icon-advantage--fin.svg");
    background-size: auto 96px;
  }
  
  &--docking::before {
    background-image: url("../images/storage/icon-advantage--docking.svg");
    background-size: auto 100px;
  }
  
  &--saving::before {
    background-image: url("../images/storage/icon-advantage--saving.svg");
    background-size: auto 96px;
  }
  
  @media (min-width: 568px) {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-shrink: 0;
    
    padding: 35px 0 12px;

    &::before {
      margin-bottom: 15px;
    }
  }
  
  @media (min-width: 768px) {
    width: calc(50% - 46px);
    flex-basis: calc(50% - 46px);
  
    padding: 40px 0 10px 0;
  
    &::before {
      margin-bottom: 6px;
    }
  }
  
  @media (min-width: 1024px) {
    width: calc(50% - 60px);
    flex-basis: calc(50% - 60px);
  
    padding: 50px 0 0 0;
  }
  
  @media (min-width: 1280px) {
    width: calc(25% - 60px);
    flex-basis: calc(25% - 60px);
  
    padding: 85px 0 0 0;
  
    &::before {
      margin-bottom: 23px;
    }
  }
  
  @media (min-width: 1500px) {
    width: calc(25% - 65px);
    flex-basis: calc(25% - 65px);
  
    padding: 65px 0 0 0;
  
    &::before {
      margin-bottom: 34px;
    }
  }
}

.advantage-item__title {
  display: block;
  
  font-size: 22px;
  font-weight: normal;
  line-height: 1.27;
  letter-spacing: normal;
  
  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 1.33;
  }
  
  @media (min-width: 1500px) {
    font-size: 28px;
    line-height: 1.14;
  }
}

.advantage-item__text {
  margin: 10px 0 0 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: normal;
  
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 1.63;
  }
  
  @media (min-width: 1280px) {
    margin-top: 20px;
  }
  
  @media (min-width: 1500px) {
    font-size: 18px;
    line-height: 1.67;
    margin-top: 23px;
  }
}
