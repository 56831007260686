.c-footer a.logo-electric-site {
  display: flex;
  //width: 170px;
  align-items: center;
  
  &::after {
    content: none;
  }
}

.logo-electric-site__img {
  display: block;
  width: 40px;
  margin-right: 5px;
  
  filter: grayscale(1);
  transition: all 0.3s ease;
}

.logo-electric-site:hover .logo-electric-site__img {
  filter: grayscale(0);
}
