@import "./keyframes.scss";

.vaccancy {
    min-height: 330px;
}

.c-title {
    margin-top: 100px;
}
.vaccancy__title {
    margin: 2.5rem 0 3.125rem;
}

.vaccancy__dropdown {
    width: 240px;
    cursor: pointer;
    align-items: center;
    background-color: #fff;
    position: relative;
}

.selected-city {
    width: 100%;
    cursor: pointer;
    background-color: transparent;
    transition: 0.15s ease-in-out all;
    color: #c9394b;
    font-size: 18px;
    z-index: 3;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 50px;

    &::after {
        position: absolute;
        content: "";
        right: 17px;
        top: 40%;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #c9394b;
    }
}

.dropdown__list {
    list-style: none;
    margin: 0;
    left: 0;
    top: 0;
    padding: 0;
    display: none;
    position: absolute;
    width: 100%;
    color: #000;
    border: 2px solid #ccc;
    border-radius: 10px;
    background: #fff;
    max-height: 250px;
    overflow-y: scroll;
    z-index: 2;
    animation: fade-out 0.1s ease;

    // Стилизация скролла для chrome
    &::-webkit-scrollbar {
        width: 12px; /* Ширина скроллбара */
    }

    &::-webkit-scrollbar-track {
        background: #ffebeb; /* Фон полосы прокрутки */
        border-radius: 0 10px 10px 0;
    }

    &::-webkit-scrollbar-thumb {
        background: #c28d8d; /* Цвет ползунка */
        border-radius: 5px; /* Радиус скругления */
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #8d6b6b; /* Цвет ползунка при наведении */
    }

    &--active {
        display: block;
        animation: fade-in 0.1s ease-in;
    }
}

// Для Firefox
* {
    scrollbar-width: 12px; /* Толщина: thin, auto */
    scrollbar-color: #8d6b6b #ffebeb; /* Цвет ползунка и трека */
    border-radius: 0 10px 10px 0;
}

.dropdown__item {
    padding: 10px;
    cursor: pointer;
    color: #c9394b;
    font-size: 18px;

    &:first-child {
        border-bottom: 2px solid #dadada;
        background-color: #fff;
        cursor: auto;
        font-size: 18px;
        border-radius: 20px;
        padding: 10px;
        &:hover {
            background: #fff;
        }
    }

    &:hover {
        background: #f0f0f0;
        border-radius: 20px;
    }

    a {
        font-size: 18px;
        text-decoration: none;
    }
}

.vaccansy__list {
    list-style-type: none;
    padding: 0;
}

.vaccansy__item {
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
}

.vaccansy__link {
    color: #c9394b;
    font-weight: 400;
    font-size: 22px;
    cursor: pointer;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.vaccansy-count {
    font-size: 20px;
    margin-left: 10px;
    color: rgb(149, 151, 153);
}

.vaccansy__group-name {
    font-size: 22px;
    color: #c9394b;
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    text-decoration: underline;
}

.vaccansy__desc {
    display: none;
    animation: slide-up 0.3s ease;

    .desc-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }

    &--opened {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        border-bottom: 1px solid #cfcfcf;
        padding: 25px 0px;
        font-size: 18px;
        animation: slide-down 0.3s ease;
    }
}

.vaccancy-salary {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vaccancy-city {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vaccancy-name {
    margin-bottom: 15px;
    font-size: 18px;
    a {
        text-decoration: none;
        color: #9e1b2b;
    }
}
