.c-oplata__main .table {
    margin-top: 20px;
    font-size: 16px;
    max-width: 800px;
    line-height: 28px;

    &:last-of-type {
        margin-bottom: 30px;
    }
}

.c-oplata__main .row {
    display: flex;
}

.c-oplata__main .row > div {
    width: 50%;
    flex: none;
    vertical-align: baseline;
}
