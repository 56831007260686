@import "./keyframes.scss";

.slide-left-full {
  z-index: 3 !important;
  animation: slide-left-full-ani ease 0.3s;
  @media (max-width: 1499px) {
    animation: slide-left-full-md ease 0.3s;
  }
}

.slide-left-short {
  animation: slide-left-short-ani ease 0.3s;
  @media (max-width: 1499px) {
    animation: slide-left-short-md ease 0.3s !important;
  }
}

.slide-right-full {
  z-index: 2 !important;
  animation: slide-right-full-ani ease 0.3s;
  @media (max-width: 1499px) {
    animation: slide-right-full-md ease 0.3s;
  }
}

.slide-right-short {
  animation: slide-right-short-ani ease 0.3s;
  @media (max-width: 1499px) {
    animation: slide-right-short-md ease 0.3s;
  }
}

.opacity-in {
  display: block;
  animation: opacity-in ease 0.3s;
}

.opacity-out {
  animation: opacity-out ease 0.3s;
}

