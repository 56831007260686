.news-page {
  @media (min-width: 1500px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.news-page__content {
  flex-grow: 1;
  max-width: 1030px;
}

.news-page__news {
  max-width: 1090px;
  @media (min-width: 568px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  @media (min-width: 768px) {
    margin: 0 -20px;
  }

  @media (min-width: 1024px) {
    margin: 0 -24px;
  }

  @media (min-width: 1280px) {
    margin: 0 -30px;
  }
}

.news-article {
  margin-bottom: 30px;

  @media (min-width: 568px) {
    width: 50%;
    flex:none;
  }

  @media (min-width: 768px) {
    margin-bottom: 33px;
  }

  @media (min-width: 1024px) {
    width: 33.33%;
    margin-bottom: 43px;
  }

  @media (min-width: 1280px) {
  }
}

.news-article__link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
}

.news-article__wrapper {
    @media (min-width: 568px) {
        margin: 0 15px;
      }

  @media (min-width: 768px) {
    margin: 0 20px;
  }

  @media (min-width: 1024px) {
    margin: 0 24px;
  }

  @media (min-width: 1280px) {
    margin: 0 30px;
  }
}

.news-article__info {
  display: flex;
  margin-bottom: 10px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;

  @media (min-width: 768px) {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 1.63;
  }
}

.news-article__type {
  //color: #c9394b;
  padding-right: 10px;
    text-decoration: none;
    &:hover {
        color: black;
    }

  @media (min-width: 768px) {
    padding-right: 12px;
  }
}

.news-article__hr {
  margin: 3px 0;
  border-right: solid 1px #b2b2b2;
}

.news-article__date {
  color: #000000;
  padding-left: 10px;

  @media (min-width: 768px) {
    padding-left: 12px;
  }
}

.news-article__img {
  order: 1;
  max-width: 280px;

  @media (min-width: 568px) {
    height: 180px;
    position: relative;
    overflow: hidden;
  }

  @media (min-width: 768px) {
    max-width: 305px;
    height: 180px;
  }
}

.news-article__img > img {
  display: block;
  filter: grayscale(100%);
  transition: filter 1s ease;

  @media (min-width: 568px) {
    max-width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}


.news-article__title {
  order: 2;
  margin-top: 15px;
  margin-bottom: 0;

  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
  line-height: 1.63;
  color: #000000;

  max-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 1.45;

    max-height: 62px;
  }
}

.news-article__link:hover .news-article__img > img,
.news-article__link:focus .news-article__img > img {
  filter: grayscale(0%);
}

.news-article__link:hover .news-article__title,
.news-article__link:focus .news-article__title {
  color: #c9394b;
}

//----------------------------------------------------------------------

.news-filter {
  flex: none;

  @media (min-width: 568px) {
    margin-bottom: 30px;
  }

  @media (min-width: 1500px) {
    order: 2;
    margin-top: 35px;
    margin-left: 60px;
  }
}

.news-filter__wrapper {
  @media (min-width: 568px) {
    display: flex;
    align-items: center;
  }

    @media (min-width: 1500px) {
        display: block;

        &--fixed {
            position: fixed;
            top: 85px;
        }
    }
}


.news-filter__checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  cursor: pointer;

  &:hover {
    color: #c9394b;
  }

  @media (min-width: 568px) {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-right: 30px;
  }

  @media (min-width: 1500px) {
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.news-filter__checkbox > input{
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding:0 !important;
  border:0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.news-filter__icon {
  position: relative;
  display: block;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  border: solid 1px #c9394b;
}

.news-filter__icon> svg {
  display: none;
  position: absolute;
  left: 3px;
  top: 4px;
}

input:checked ~ .news-filter__icon > svg {
  display: block;
}

//---------------------------------------------

.news-pagination {
  @media (min-width: 568px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.news-pagination__list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 0;
  padding: 0;
  list-style: none;
}

.news-pagination__item {
  display: block;
  margin-right: 10px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;

  @media (min-width: 1024px) {
    margin-right: 15px;
    font-size: 26px;
  }
}

.news-pagination__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 50px;

  text-decoration: none;
  border: solid 1px #151515;
  color: #292d36;

  &:hover,
  &:focus {
    outline: 1px solid #151515;
  }

  &--active {
    border-color: #c9394b;
    color: #c9394b;
  }

  &--active:hover,
  &--active:focus {
    outline: none;
  }

  @media (min-width: 1024px) {
    width: 66px;
    height: 72px;
  }
}

.news-pagination__skip {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  @media (min-width: 1024px) {
    height: 72px;
  }
}

.news-pagination .c-button {
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: 568px) {
    order: 2;
    width: auto;
    margin-bottom: 0;
    margin-left: auto;

    height: 50px;
    padding: 17.5px 20.5px 13.5px;
    font-size: 14px;
  }

  @media (min-width: 768px) {
    height: 50px;
    padding: 17.5px 20.5px 13.5px;
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    height: 72px;
    padding: 23.5px 30.5px 21.5px;
    font-size: 22px;
  }
}



.c-header-menu .c-header-menu__item--company {
  & > a > span {
    color: #151515;
  }

  & > a::before {
    background-image: url(/assets/images/icons/angle-down.svg);
  }

  & .c-header-submenu > li > a {
    color: #151515 !important;
    &:hover {
      color: #c9394b !important;
    }
  }
}

//---------------------------------------------------

.news-detail__back a {
  position: relative;
  padding-left: 14px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  color: #151515;

  &::before {
    content: '';
    position: absolute;
    left: -4px;
    top: 0;
    display: block;
    width: 14px;
    height: 22px;
    background-image: url(/assets/images/icons/angle-down.svg);
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(90deg);
  }

  &:hover {
    color: #c9394b;
  }

  &:hover::before {
    background-image: url(/assets/images/icons/angle-down-red.svg);
  }
}

.news-detail__article {
  padding-top: 20px;
  padding-bottom: 40px;
  max-width: 785px;
}

.news-detail__img, .news-detail__img > img {
  display: block;

    @media (min-width: 768px) {
        max-height: 400px;
    }

    @media (min-width: 1024px) {
        max-height: 430px;
    }
}

.news-detail__title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #c9394b;

  @media (min-width: 768px) {
    margin-top: 40px;
    margin-bottom: 15px;
    font-size: 32px;
    line-height: 1.06;
  }

  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 1.2;
  }
}

.news-detail__info {
  order: 1;
  display: flex;
  margin-bottom: 10px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;

  @media (min-width: 768px) {
    margin-bottom: 15px;
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
}

.news-detail__type {
  color: #c9394b;
    text-decoration: none;
  padding-right: 10px;

    &:hover,
    &:active {
        color: black;
    }

  @media (min-width: 768px) {
    padding-right: 12px;
  }

  @media (min-width: 1024px) {
    padding-right: 20px;
  }
}

.news-detail__hr {
  border-right: solid 1px #b2b2b2;
}

.news-detail__date {
  color: #000000;
  padding-left: 10px;

  @media (min-width: 768px) {
    padding-left: 12px;
  }

  @media (min-width: 1024px) {
    padding-left: 20px;
  }
}

.news-detail__content {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: #000000;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 1.63;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 1.67;
  }


  p {
    margin: 15px 0;
  }

  ul, ol {
    margin: 10px 0 20px 0;
    padding-left: 22px;

    @media (min-width: 1024px) {
      padding-left: 31px;
      margin: 30px 0 30px 0;
    }
  }

  li + li {
    margin-top: 10px;
  }

  h2 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: #000000;
    text-transform: none;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      font-size: 22px;
      line-height: normal;
    }

    @media (min-width: 1024px) {
      font-size: 36px;
      line-height: normal;
      margin-bottom: 30px;
    }
  }

  p + h2,
  ul + h2,
  li + h2 {
    margin-top: 30px;

    @media (min-width: 1024px) {
      margin-top: 75px;
    }
  }

    a {
        text-decoration: none;
    }

  a:hover {
    color: black;
  }
}

//-----------------------------------------

.related-news {
  padding: 30px 0 60px 0;
  background-color: #f5f5f5;

  @media (min-width: 768px) {
    padding: 40px 0 70px 0;
  }
}

.related-news__title {
  display: block;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #151515;

  @media (min-width: 768px) {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 1.06;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: normal;
  }
}
