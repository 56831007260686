.storage-saving {
  padding-top: 32px;
  padding-bottom: 40px;
  
  font-family: Montserrat, sans-serif;
  
  @media (min-width: 568px) and (max-width: 767px) {
    padding-top: 28px;
    padding-bottom: 0px;
  }
  
  @media (min-width: 768px) {
    padding-top: 60px;
    padding-bottom: 55px;
  }
  
  @media (min-width: 1024px) {
    padding: 0;
    height: 492px;
  }
  
  @media (min-width: 1280px) {
    padding: 0;
    height: 505px;
  }
  
  @media (min-width: 1500px) {
    height: 600px;
  }
}

.storage-saving__inner {
  padding: 0 10px 0 20px;
  
  @media (min-width: 568px) and (max-width: 767px) {
    display: flex;
    flex-direction: row-reverse;
  }
  
  @media (min-width: 768px) {
    width: 650px;
    margin: 0 auto;
    padding: 0;
  }
  
  @media (min-width: 1024px) {
    position: relative;
    width: 944px;
    height: 100%;
  }
  
  @media (min-width: 1280px) {
    width: 1200px;
  }
  
  @media (min-width: 1500px) {
    width: 1400px;
  }
}

.storage-saving__content {
  @media (min-width: 568px) and (max-width: 767px) {
    width: 55%;
    flex-basis: 55%;
    flex-shrink: 1;
    
    padding-right: 10px;
    box-sizing: border-box;
  }
  
  @media (min-width: 1024px) {
    position: absolute;
    left: 0;
    top: 108px;
    width: 45%;
  }
  
  @media (min-width: 1280px) {
    position: absolute;
    left: 0;
    top: 58px;
    width: 50%;
  }
  
  @media (min-width: 1500px) {
    top: 104px;
  }
}

.storage-saving__picture {
  display: block;
  width: 100%;
  
  @media (min-width: 1024px) {
    position: absolute;
    right: -24px;
    top: 30px;
    
    width: 586px;
    height: 411px;
    z-index: -1;
  }
  
  @media (min-width: 1280px) {
    right: -12px;
    top: 15px;
  }
  
  @media (min-width: 1500px) {
    width: 696px;
    height: 488px;
    right: -31px;
    top: 32px;
  }
}

.storage-saving__img {
  width: 100%;
  margin-bottom: 13px;
  
  @media (min-width: 768px) {
    margin-bottom: 32px;
  }
  
  @media (min-width: 1024px) {
    margin: 0;
  }
}

.storage-saving__title {
  margin-bottom: 5px;
  
  font-size: 26px;
  font-weight: 200;
  line-height: 1.27;
  letter-spacing: 0.3px;
  color: #c9394b;
  
  @media (min-width: 568px) and (max-width: 767px) {
    margin-top: 7px;
    margin-bottom: 10px;
    
    font-size: 40px;
    line-height: 1.25;
    letter-spacing: 0.5px;
  }
  
  @media (min-width: 768px) {
    margin-bottom: 21px;
    
    font-size: 84px;
    line-height: 1.19;
    letter-spacing: 1.05px;
  }
  
  @media (min-width: 1024px) {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 60px;
    line-height: 1.2;
    letter-spacing: 0.75px;
  }
  
  @media (min-width: 1280px) {
    font-size: 84px;
    letter-spacing: 1.05px;
    margin-bottom: 23px;
  }
  
  @media (min-width: 1500px) {
    font-size: 100px;
    line-height: 1.05;
    letter-spacing: 1.25px;
    margin-bottom: 27px;
  }
}

.storage-saving__text{
  margin: 0;
  
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: normal;
  color: #000000;
  
  @media (min-width: 568px) and (max-width: 767px) {
    font-size: 16px;
    line-height: 1.5;
  }
  
  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 1.43;
  }
  
  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 1.42;
  }
  
  @media (min-width: 1280px) {
    font-size: 30px;
    line-height: 1.33;
  }
  
  @media (min-width: 1500px) {
    font-size: 36px;
    line-height: 1.39;
  }
}
.animation-text-storage-on {
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s ease;
}

.animation-text-storage {
  transform: translateY(50px);
  opacity: 0;
}

