.storage-technic {
  padding-top: 50px;
  padding-bottom: 20px;
  overflow: hidden;
  
  font-family: Montserrat, sans-serif;
  
  @media (min-width: 1024px) {
    height: 691px;
    padding-top: 0;
  }
  
  @media (min-width: 1280px) {
    height: 1021px;
  }
  
  @media (min-width: 1500px) {
    height: 1103px;
  }
}

.storage-technic__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  
  padding: 0 20px;
  
  @media (min-width: 768px) {
    width: 650px;
    margin: 0 auto;
    padding: 0;
  }
  
  @media (min-width: 1024px) {
    width: 944px;
  }
  
  @media (min-width: 1500px) {
    width: 1400px;
  }
}

.storage-technic__picture {
  display: block;
  
  margin-left: -20px;
  margin-top: 30px;
  
  @media (min-width: 768px) {
    position: absolute;
    right: 0;
    top: 77px;
    width: 818px;
    height: 304px;
    margin: 0;
  }
  
  @media (min-width: 1024px) {
    right: 157px;
    top: 20px;
    width: 1565px;
    height: 611px;
  }
  
  @media (min-width: 1280px) {
    right: 14px;
    top: 58px;
    width: 2211px;
    height: 864px;
  }
  
  @media (min-width: 1500px) {
    right: 197px;
    top: 120px;
    width: 2211px;
    height: 864px;
  }
}

.storage-technic__img {
  display: block;
  width: 100%;
}

.storage-technic__title {
  margin: 0;
  order: -1;
  
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 200;
  line-height: 1.55;
  letter-spacing: 0.33px;
  color: #c9394b;
  
  @media (min-width: 768px) {
    font-size: 32px;
    font-weight: 200;
    line-height: 1.06;
    letter-spacing: 0.4px;
  }
  
  @media (min-width: 1024px) {
    margin-left: 578px;
    margin-top: 95px;
    width: 200px;
    
    font-size: 36px;
    line-height: 1.22;
    letter-spacing: 0.45px
  }
  
  @media (min-width: 1280px) {
    margin-left: 599px;
    margin-top: 129px;
    width: auto;
  
    font-size: 70px;
    line-height: 1.29;
    letter-spacing: 0.88px;
  }
  
  @media (min-width: 1500px) {
    margin-left: 880px;
    margin-top: 142px;
  
    font-size: 80px;
    line-height: 1.25;
    letter-spacing: 1px;
  }
}

.storage-technic__title span {
  display: block;
  
  @media (min-width: 568px) {
    display: inline;
  }
  
  @media (min-width: 1024px) {
    display: block;
  }
}

.storage-technic__title .big {
  text-transform: none;
  font-size: 50px;
  line-height: 1;
  letter-spacing: 0.63px;
  
  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 0.83;
    letter-spacing: 0.75px;
    margin-bottom: 8px;
  }
  
  @media (min-width: 1280px) {
    font-size: 130px;
    line-height: 0.77;
    letter-spacing: 1.63px;
    margin-bottom: 20px;
  }
  
  @media (min-width: 1500px) {
    font-size: 150px;
    line-height: 0.67;
    letter-spacing: 1.88px;
  }
}

.storage-technic__list {
  margin: 28px 0 0 0;
  padding: 0;
  list-style: none;
  
  @media (min-width: 568px) {
    display: flex;
    justify-content: space-between;
  }
  
  @media (min-width: 768px) {
    margin-top: 358px;
  }
  
  @media (min-width: 1024px) {
    margin-top: 50px;
    margin-left: 585px;
    display: block;
  }
  
  @media (min-width: 1280px) {
    margin-top: 70px;
    margin-left: 611px;
  }
  
  @media (min-width: 1500px) {
    margin-top: 51px;
    margin-left: 890px
  }
}

.storage-technic__item {
  padding-bottom: 30px;
  
  @media (min-width: 568px) and (max-width: 767px) {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-shrink: 0;
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    width: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
    flex-shrink: 0;
  }
  
  @media (min-width: 1024px) {
    padding-bottom: 45px;
  }
  
  @media (min-width: 1280px) {
    padding-bottom: 38px;
  }
}

.storage-technic__item-title {
  display: block;
  
  font-size: 22px;
  font-weight: normal;
  line-height: 1.27;
  color: #151515;
  
  @media (min-width: 1280px) {
    font-size: 24px;
    line-height: 1.88;
  }
  
  @media (min-width: 1500px) {
    font-size: 28px;
    line-height: 1.61;
  }
}

.storage-technic__item-text {
  margin: 10px 0 0 0;
  
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  color: #151515;

  
  @media (min-width: 1280px) {
    font-size: 16px;
    line-height: 1.88;
    margin: 0;
  }
  
  @media (min-width: 1500px) {
    font-size: 18px;
    line-height: 1.67;
    margin-top: 12px;
  }
}
