@keyframes slide-left-full-ani {
  0% {
    clip: rect(auto, 121px, auto, auto);
  }


  100% {
    clip: rect(auto, 245px, auto, auto);
  }

}

@keyframes slide-left-short-ani {
  0% {
    z-index: 2;
    clip: rect(auto, 245px, auto, auto);
  }

  99% {
    z-index: 2;
  }

  100% {
    z-index: 1;
    clip: rect(auto, 121px, auto, auto);
  }
}

@keyframes slide-left-short-md {
  0% {
    z-index: 2;
    clip: rect(auto, 142px, auto, auto);
  }

  99% {
    z-index: 2;
  }

  100% {
    z-index: 1;
    clip: rect(auto, 72px, auto, auto);
  }
}

@keyframes slide-left-full-md {
  0% {
    clip: rect(auto, 72px, auto, auto);
  }

  100% {
    clip: rect(auto, 142px, auto, auto);
  }
}

@keyframes slide-right-full-ani {
  0% {
    clip: rect(auto, auto, auto, 127px);
  }
  100% {
    clip: rect(auto, auto, auto, 0);
  }
}

@keyframes slide-right-short-ani {
  0% {
    z-index: 2;
    clip: rect(auto, auto, auto, 0);
  }

  99% {
    z-index: 2;
  }

  100% {
    z-index: 1;
    clip: rect(auto, auto, auto, 127px);
  }
}

@keyframes slide-right-full-md {
  0% {
    clip: rect(auto, auto, auto, 72px);
  }

  100% {
    clip: rect(auto, auto, auto, 0);
  }
}

@keyframes slide-right-short-md {
  0% {
    z-index: 2;
    clip: rect(auto, auto, auto, 0);
  }

  99% {
    z-index: 2;
  }
  100% {
    z-index: 1;
    clip: rect(auto, auto, auto, 72px);
  }
}


@keyframes opacity-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes opacity-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes bar-center-left {
  0% {
    top: -16px;
    left: 49%;
    height: 205px;

  }

  100% {
    top: 4px;
    left: 8%;
    height: 139px;
  }
}

@keyframes bar-center-left-md {
  0% {
    left: 49%;
    height: 122px;
    top: 0;
  }
  100% {
    left: 8%;
    height: 83px;
    top: 15px;
  }
}

@keyframes bar-left-center {
  0% {
    top: 4px;
    left: 8%;
    height: 139px;
  }

  100% {
    top: -16px;
    left: 49%;
    height: 205px;

  }
}


@keyframes bar-left-center-md {
  0% {
    height: 83px;
    top: 15px;
    left: 8%;
  }

  100% {
    left: 49%;
    height: 122px;
    top: 0;
  }
}

@keyframes bar-center-right {
  0% {
    top: -16px;
    left: 49%;
    height: 205px;
  }
  100% {
    top: 4px;
    left: 91%;
    height: 139px;
  }
}

@keyframes bar-center-right-md {
  0% {
    left: 49%;
    height: 122px;
    top: 0;
  }

  100% {
    left: 91%;
    height: 83px;
    top: 15px;
  }
}

@keyframes bar-right-center {
  0% {
    top: 4px;
    left: 90%;
    height: 139px;
  }

  100% {
    top: -16px;
    left: 49%;
    height: 205px;
  }
}

@keyframes bar-right-center-md {
  0% {
    left: 90%;
    height: 83px;
    top: 15px;
  }

  100% {
    left: 49%;
    height: 122px;
    top: 0;
  }
}
