.storage-form {
  position: relative;
  padding-top: 50px;
  padding-bottom: 20px;
  background-color: #151515;
  
  @media (min-width: 768px) {
    padding-bottom: 5px;
  }
  
  @media (min-width: 1280px) {
    padding-top: 80px;
    padding-bottom: 110px;
  }
  
  @media (min-width: 1500px) {
    padding-top: 100px;
  }
}

.storage-form .c-title {
  @media (min-width: 568px) {
    font-size: 26px;
    line-height: 1.15;
    letter-spacing: 0.35px
  }
  
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 1.06;
    letter-spacing: 0.43px;
  }
  
  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 0.83;
    letter-spacing: 0.48px;
  }
  
  @media (min-width: 1280px) {
    font-size: 70px;
    line-height: 1.29;
    letter-spacing: 0.88px;
  }
  
  @media (min-width: 1500px) {
    font-size: 80px;
    line-height: 1.25;
    letter-spacing: 1px;
  }
}

.storage-form .c-form__policy,
.storage-form .c-resume-manager p,
.storage-form .c-description--form {
  color: #ffffff;
}

.storage-form .c-form__footer,
.storage-form .l-grid-col--resume-info {
  @media (min-width: 568px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: stretch;
  }
}

.storage-form .c-button,
.storage-form .c-form__policy,
.storage-form .c-resume-manager {
  @media (min-width: 568px) {
    width: calc(50% - 7px);
    flex-basis: calc(50% - 7px);
  }
  
  @media (min-width: 768px) {
    width: 100%;
    flex-basis: 100%;
  }
}

.storage-form .c-form__footer {
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
  
  @media (min-width: 1280px) {
    margin-top: 91px;
  }
}

.storage-form .c-button {
  @media (min-width: 1024px) {
    width: 214px;
    flex-basis: 214px;
    margin-right: 20px;
  }
}

.storage-form .l-grid-col--resume-form {
  @media (min-width: 1024px) {
    width: 627px;
    flex-basis: 627px;
  }
}

.storage-form .l-grid-col--resume-info {
  @media (min-width: 768px) {
    display: block;
  }
  @media (min-width: 1024px) {
    flex-grow: 1;
    padding-left: 108px;
  }
  
  @media (min-width: 1280px) {
    flex-grow: 1;
    padding-left: 78px;
  }
}

.storage-form .c-resume-manager {
  @media (min-width: 1280px) {
    margin-bottom: 41px;
  }
}

.storage-form .c-resume-manager p {
  @media (min-width: 1280px) {
    display: inline-block;
  }
}

.storage-form .c-resume-manager h4 {
  @media (min-width: 1280px) {
    margin-bottom: 8px;
  }
}

.c-storage__result {
  position: absolute;
  transition: .3s ease opacity;
  opacity: 0;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  padding: 60px 0 100px;
}

.c-storage-form-wrap {
  transition: .3s ease opacity;
}

.storage-form.show-result .c-storage__result {
  opacity: 1;
  pointer-events: auto;
  transition-delay: .3s;
}

.storage-form.show-result .c-storage-form-wrap {
  opacity: 0;
  pointer-events: none;
}
